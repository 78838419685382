<template>
  <div>
    <div class="row mt-3">
      <div class="col-md-12 ml-auto mr-auto">
        <div class="subtitle-1 mb-3">Units</div>
        <hr>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 mt-4 mb-4" id="course_units_list">
        <admin-show-unit-button v-for="unit in units"
                          :key="unit.id"
                          :active="true"
                          :course-id="courseId"
                          :unit-id="unit.id"
                          :unit-title="unit.title">
        </admin-show-unit-button>
        <admin-add-unit-button v-bind:course-id="courseId"></admin-add-unit-button>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 ml-auto mr-auto">
        <div class="subtitle-1 mb-3">Modules</div>
        <hr>
      </div>
    </div>
  </div>
</template>
<script>
  import CareerCamp from '../globals';
  import axios from 'axios';
  import AdminShowUnitButton from '../components/admin_show_unit_button.vue';
  import AdminAddUnitButton from '../components/admin_add_unit_button.vue';

  export default {
    name: 'admin-units-list-buttons-view',
    props: {
      courseId: String,
      onlyRead: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        units: [],
      }
    },
    components: {
      AdminShowUnitButton,
      AdminAddUnitButton
    },
    created: function() {
      this.bindEvents();
    },
    destroyed() {},
    mounted() {
      this.fetchUnits();
    },
    watch: {},
    methods: {
      bindEvents: function() {
        const self = this;
        CareerCamp.EventBus.$on('course:units:created', function(data) {
          self.fetchUnits();
        });

        CareerCamp.EventBus.$on('course:units:removed', function(data) {
          self.fetchUnits();
        });
      },
      fetchUnits: function() {
        const self = this;
        try {
          axios.get('/admin/courses/' + self.courseId + '/units')
            .then(function (response) {
              self.loadUnitsData(response.data);
            })
            .catch(function (error) {
              console.log(error);
            })
        }
        catch(e) {
          console.log(e)
        }
      },
      loadUnitsData: function(data) {
        this.units = data;
      }
    }
  };
</script>
