<template>
  <div class="btn-group course-module-button-group">
    <b-button @click.prevent="showUnit"
              data-turbolinks="false"
              class="btn btn-md mb-3 mr-4 btn-white course-module-button"
              v-bind:class="{ 'active-unit-btn': isActive }"
              title="Show unit modules">
      <span class="course-unit-title">{{ unitTitle }}</span>
    </b-button>

    <b-dropdown v-if="!onlyRead" toggle-class="btn btn-white course-module-button-toggle" no-caret>
      <template v-slot:button-content>
        <svg xmlns="http://www.w3.org/2000/svg"
             width="16"
             height="16"
             viewBox="0 0 24 24"
             fill="none"
             stroke="currentColor"
             stroke-width="2"
             stroke-linecap="round"
             stroke-linejoin="round"
             class="feather feather-more-vertical">
          <circle cx="12" cy="12" r="1"></circle>
          <circle cx="12" cy="5" r="1"></circle>
          <circle cx="12" cy="19" r="1"></circle>
        </svg>
      </template>
      <b-dropdown-item @click="openRenameModal">Rename</b-dropdown-item>
      <b-dropdown-item @click="openRemoveModal">Remove</b-dropdown-item>
    </b-dropdown>

    <b-modal ref="unit-modal" hide-footer title="Edit unit" centered>
      <form ref="form" @submit.stop.prevent="handleRenameOk">
        <b-form-group
          label="Unit name"
          label-for="unit-name-input"
          invalid-feedback="Unit name is required"
        >
          <b-form-input
            id="unit-name-input"
            v-model="unitTitle"
            required
          ></b-form-input>
        </b-form-group>
      </form>
      <div class="mb-2">
        <b-button @click="handleRenameOk">Save</b-button>
      </div>
    </b-modal>

    <b-modal ref="unit-remove-modal" hide-footer title="Remove unit" centered>
      <p class="my-4">Are you really sure to remove {{ unitTitle }} ?</p>
      <div class="mb-2">
        <b-button @click="handleRemoveOk">Remove</b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
  import CareerCamp from '../globals';
  import axios from 'axios';

  export default {
    name: 'admin-show-unit-button',
    data: function() {
      return {
        isActive: false,
      };
    },
    props: {
      active: Boolean,
      courseId: String,
      unitId: String,
      unitTitle: String,
      onlyRead: Boolean,
    },
    created: function() {
      if(this.active) {
        this.isActive = this.active;
        CareerCamp.EventBus.$emit('course:units:show', this.unitId);
      }
      this.bindEvents();
    },
    methods: {
      showUnit: function() {
        CareerCamp.EventBus.$emit('course:units:show', this.unitId);
      },
      bindEvents: function() {
        const self = this;
        CareerCamp.EventBus.$on('course:units:show', function(data) {
          if(self.unitId == data) {
            self.isActive = true;
          } else {
            self.isActive = false;
          }
        });
      },
      openRenameModal: function() {
        this.$refs['unit-modal'].show();
      },
      openRemoveModal: function() {
        this.$refs['unit-remove-modal'].show();
      },
      handleRemoveOk: function() {
        const self = this;
        const path = '/admin/courses/' + self.courseId + '/units/' + self.unitId

        axios.delete(path, {
          id: self.unitId
        }).then(function (response) {
          CareerCamp.EventBus.$emit('course:units:removed');
          CareerCamp.EventBus.$emit('course:notifications:saved');
          self.$refs['unit-remove-modal'].hide()
        })
        .catch(function (error) {
          console.log(error);
        })
      },
      handleRenameOk: function() {
        const self = this;
        const path = '/admin/courses/' + self.courseId + '/units/' + self.unitId

        axios.put(path, {
          course_unit: {
            title: self.unitTitle,
          }
        }).then(function (response) {
          CareerCamp.EventBus.$emit('course:units:updated');
          CareerCamp.EventBus.$emit('course:notifications:saved');
          self.$refs['unit-modal'].hide()
        })
        .catch(function (error) {
          console.log(error);
        })
      }
    }
  };
</script>
