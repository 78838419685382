<template>
  <div class="col-md-12 card">
    <h6 class="mb-4">Create new Activity</h6>
    <div class="justify-content-center">
      <a @click.prevent="selectAssignment" v-bind:class="{ active: isActiveSelectedType('assignment') }" data-turbolinks="false" disabled="true" class="btn btn-md mb-3 mr-4 btn-white course-module-button">Assignment</a>
      <a @click.prevent="selectQuestion" v-bind:class="{ active: isActiveSelectedType('question') }" data-turbolinks="false" class="btn btn-md mb-3 mr-4 btn-white course-module-button">Question</a>
      <a @click.prevent="selectMaterial" v-bind:class="{ active: isActiveSelectedType('material') }" data-turbolinks="false" disabled="true" class="btn btn-md mb-3 mr-4 btn-white course-module-button">Material</a>
    </div>

    <div v-if="selectedType == 'question'" class="question-form">
      <span class="subtitle-2">Question</span>
      <input type="text" v-model="newActivityTitle" class="activity-input" placeholder="Title" />
      <input v-model="newActivityQuestion" type="text" placeholder="Type your question here" class="activity-input">
      <textarea v-model="newActivityDescription" type="text" placeholder="Add description" class="activity-input longer-text"></textarea>

      <div class="row mb-3">
        <div class="col-md-8">
          <span class="subtitle-2">Answer</span>
          <b-form-select v-model="newActivityAnswerType" :options="activityAnswerTypeOptions" size="md"></b-form-select>
        </div>
        <div class="col-md-8 p-3" v-if="isActivityAnswersVisible()">
          <ul>
            <li v-for="(activityAnswer, index) in activityAnswers" :key="activityAnswer.id">
              <input v-model="activityAnswer.text" class="activity-answer-input">
            </li>
          </ul>
          <a @click.prevent="addNewActivityAnswer" data-turbolinks="false">Add option</a>
        </div>
      </div>
    </div>

    <div v-if="selectedType == 'assignment'" class="assignment-form">
      <span class="subtitle-2">Assignment</span>
      <input type="text" v-model="newActivityTitle" class="activity-input" placeholder="Title" />
      <textarea v-model="newActivityDescription" type="text" placeholder="Add description" class="activity-input longer-text">
      </textarea>
    </div>

    <div v-if="selectedType == 'material'" class="material-form">
      <span class="subtitle-2">Material</span>
      <input type="text" v-model="newActivityTitle" class="activity-input" placeholder="Title" />
      <textarea v-model="newActivityDescription" type="text" placeholder="Add description" class="activity-input longer-text">
      </textarea>
    </div>

    <div class="row" v-if="selectedType != null">
      <div class="col-md-12 mt-4">
        <span class="subtitle-1">Tutorial</span>
        <hr>
        <span class="subtitle-2">Upload Tutorial Video</span>
        <div class="dropzone dropzone-default dz-clickable activity-tutorial-videos" ref="attachments" data-controller="dropzone" data-dropzone-max-file-size="200" data-dropzone-max-files="1">
          <input multiple="multiple" data-target="dropzone.input" data-direct-upload-url="/rails/active_storage/direct_uploads" type="file" name="unit_module_ativity[tutorials][]">
          <div class="dropzone-msg dz-message">
            <span class="dropzone-msg-title">Drag here to upload tutorial video or click here to browse</span>
          </div>
        </div>
        <br>
        <label>Tutorial Video Label</label>
        <textarea v-model="tutorialLabel" type="text" placeholder="Add Tutorial Video label" class="activity-input longer-text">
        </textarea>
      </div>
    </div>

    <div class="row" v-if="selectedType != null">
      <div class="col-md-12 mt-4">
      <span class="subtitle-1">Attachments</span>
      <hr>
        <span class="subtitle-2">Upload attachments</span>
        <div class="dropzone dropzone-default dz-clickable activity-attachments" ref="assignment_attachments" data-controller="dropzone" data-dropzone-max-file-size="200" data-dropzone-max-files="10">
          <input multiple="multiple" data-target="dropzone.input" data-direct-upload-url="/rails/active_storage/direct_uploads" type="file" name="unit_module_ativity[files][]">
          <div class="dropzone-msg dz-message">
            <span class="dropzone-msg-title">Drag here to upload attachment or click here to browse</span>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-md-12">
        <a @click.prevent="submitNewActivity" data-turbolinks="false" class="btn btn-sm medium-text-bold no-border nav-link nav-link-cc active right">Save</a>
      </div>
    </div>

  </div>
</template>
<script>
  import CareerCamp from '../globals';
  import axios from 'axios';

  export default {
    props: {
      courseId: String,
      unitId: String,
      unitModule: Object,
      activity: Object
    },
    components: {
    },
    data() {
      return {
        selectedType: null,
        newActivityQuestion: null,
        newActivityDescription: null,
        newActivityAnswerType: null,
        newActivityTitle: '',
        activityAnswerTypeOptions: [
          { value: 'single_choice', text: 'Single Choice' },
          { value: 'multiple_choice', text: 'Multiple Choice' },
          { value: 'text', text: 'Text' },
          { value: 'file_upload', text: 'File upload' }
        ],
        activityAnswers: [{ id: 1, text: 'Option 1' }],
        attachments: [],
        tutorials: [],
        tutorialLabel: '',
      }
    },
    created: function() {
      this.bindEvents();
    },
    destroyed() {},
    mounted() {
    },
    watch: {
      activeUnitId: function() {
        this.fetchUnitModules();
      }
    },
    methods: {
      bindEvents: function() {
        const self = this;
      },
      selectionUpdate: function() {
        // TODO
      },
      selectAssignment: function() {
        this.selectedType = 'assignment';
        this.selectionUpdate();
      },
      isActiveSelectedType: function(current) {
        return this.selectedType == current;
      },
      selectQuiz: function() {
        this.selectedType = 'quiz';
        this.selectionUpdate();
      },
      selectQuestion: function() {
        this.selectedType = 'question';
        this.selectionUpdate();
      },
      selectMaterial: function() {
        this.selectedType = 'material';
        this.selectionUpdate();
      },
      generateBodyForActivity: function() {
        if(this.selectedType == 'question') {
          if(this.newActivityAnswerType == 'single_choice' || this.newActivityAnswerType == 'multiple_choice')
            return {
              question: this.newActivityQuestion,
              description: this.newActivityDescription,
              answer_type: this.newActivityAnswerType,
              answers: this.activityAnswers,
            }
          else {
            return {
              question: this.newActivityQuestion,
              description: this.newActivityDescription,
              answer_type: this.newActivityAnswerType
            }
          }
        } else if(this.selectedType == 'assignment') {
          return {
            description: this.newActivityDescription
          }
        } else if(this.selectedType == 'material') {
          return {
            description: this.newActivityDescription
          }
        }
      },
      submitNewActivity: function() {
        const self = this;
        const path = '/admin/courses/' + self.courseId + '/units/' + self.unitId + '/unit_modules/' + self.unitModule.id + '/unit_module_activities'

        self.processAttachments();
        self.processTutorial();

        axios.post(path, {
          unit_module_activity: {
            body: self.generateBodyForActivity(),
            activity_type: self.selectedType,
            title: self.newActivityTitle,
            unit_module_id: self.unitModule.id,
            files: self.attachments,
            tutorials: self.tutorials,
            tutorial_label: self.tutorialLabel,
          }
        }).then(function (response) {
            self.attachments = [];
            self.tutorials = [];
            self.newActivityTitle = '';
            self.tutorialLabel = '';
            CareerCamp.EventBus.$emit('course:units:activity:created');
            CareerCamp.EventBus.$emit('course:notifications:saved');
            self.$parent.$el.scrollIntoView({behavior: 'smooth'});
          })
          .catch(function (error) {
            console.log(error);
          })
      },
      processAttachments: function() {
        const self = this;
        var attachment_inputs = []
        if(this.selectedType == 'question') {
          attachment_inputs = document.querySelectorAll('.activity-attachments input[name="unit_module_ativity[files][]"]')
        } else if(this.selectedType == 'assignment') {
          attachment_inputs = document.querySelectorAll('.activity-attachments input[name="unit_module_ativity[files][]"]')
        } else if(this.selectedType == 'material') {
          attachment_inputs = document.querySelectorAll('.activity-attachments input[name="unit_module_ativity[files][]"]')
        }
        console.log({ attachment_inputs: attachment_inputs })
        Array.prototype.forEach.call(attachment_inputs, function(el, i) {
          if(el.value != '') {
            self.attachments.push(el.value);
          }
        });
      },
      processTutorial: function() {
        const self = this;
        const tutorials_inputs = document.querySelectorAll('.activity-tutorial-videos input[name="unit_module_ativity[tutorials][]"]')
        Array.prototype.forEach.call(tutorials_inputs, function(el, i) {
          if(el.value != '') {
            self.tutorials.push(el.value);
          }
        });
      },
      addNewActivityAnswer: function() {
        const last_id = this.activityAnswers[this.activityAnswers.length - 1].id
        const next_id = last_id + 1

        this.activityAnswers.push({
          id: next_id,
          text: 'Option ' + next_id
        })
      },
      isActivityAnswersVisible: function() {
        if(this.newActivityAnswerType == 'single_choice' || this.newActivityAnswerType == 'multiple_choice') {
          return true
        } else {
          return false
        }
      }
    }
  };
</script>
