<template>
  <div class="add-new-unit">
    <b-button id="show-btn" @click="showModal" class="btn btn-white mx-1 nav-link btn-md">+ Add new Unit</b-button>
    <b-modal ref="unit-modal" hide-footer title="Create new unit" centered>
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group
          label="Unit name"
          label-for="unit-name-input"
          invalid-feedback="Unit name is required"
        >
          <b-form-input
            id="unit-name-input"
            v-model="unitName"
            required
          ></b-form-input>
        </b-form-group>
      </form>
      <div class="mb-2">
        <b-button @click="handleOk">Save</b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
  import CareerCamp from '../globals';
  import axios from 'axios';

  export default {
    props: {
      courseId: String,
    },
    data: function(){
      return {
        unitName: '',
      };
    },
    methods: {
      showModal() {
        this.$refs['unit-modal'].show()
      },
      handleOk(bvModalEvt) {
        bvModalEvt.preventDefault()
        this.handleSubmit()
      },
      handleSubmit() {
        const self = this;
        const path = '/admin/courses/' + self.courseId + '/units/'

        axios.post(path, {
          course_unit: {
            title: self.unitName,
            course_id: self.courseId
          }
        }).then(function (response) {
          CareerCamp.EventBus.$emit('course:units:created');
          CareerCamp.EventBus.$emit('course:notifications:saved');
          self.unitName = '';
          self.$refs['unit-modal'].hide()
        })
        .catch(function (error) {
          console.log(error);
        })
      }
    }
  }
</script>
