import $ from 'jquery/dist/jquery'
// import Vue from 'vue/dist/vue.esm.js';
import Vue from 'vue/dist/vue.js'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import Turbolinks from './mixins/turbolinks';
import TurbolinksAdapter from 'vue-turbolinks';
// import BootstrapVue from 'bootstrap-vue'
import Quill from 'quill/dist/quill'
import 'select2/dist/js/select2'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

$(document).ready(function() {
  $('.select2').select2({
    allowClear: false
  });

  $('.select2-with-create').select2({
    allowClear: false,
    tags: true
  })

  $('.select2-multiple').select2({
    allowClear: false,
    multiple: true,
  })
});

document.addEventListener("DOMContentLoaded", function (event) {
  if(document.querySelector('#editor-container') !== null) {
    var quill = new Quill('#editor-container', {
      modules: {
        toolbar: [
          [{ header: [1, 2, false] }],
          ['bold', 'italic', 'underline'],
          ['link']
        ]
      },
      theme: 'snow'
    });

    document.querySelector('form').onsubmit = function () {
      var body = document.querySelector('input[class=text-editor]');
      body.value = quill.root.innerHTML
    };
  }

  // Extended editor initialization
  var toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    ['blockquote', 'code-block'],

    [{ 'header': 1 }, { 'header': 2 }],               // custom button values
    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
    [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
    [{ 'direction': 'rtl' }],                         // text direction

    [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    [{ 'font': [] }],
    [{ 'align': [] }],

    ['clean']                                         // remove formatting button
  ];

  var extendedEditorContainers = document.querySelectorAll('.extended-editor-container');
  extendedEditorContainers.forEach(function(extendedEditorContainer) {
    var quill = new Quill(extendedEditorContainer, {
      modules: {
        toolbar: toolbarOptions,
      },
      theme: 'snow'
    });

    quill.on('text-change', function() {
      var extendedEditorBody = $(extendedEditorContainer).parent().find('input[class=extended-text-editor]')
      $(extendedEditorBody).val(quill.root.innerHTML)
    });
  })
});

//
// // require("@rails/ujs").start()
// // require("@rails/activestorage").start()
// // require("channels")
// // require('scrollreveal')
// // require('cleave.js')
// // require('select2')
//
// // import '../stylesheets/application'
// // import './bootstrap_custom.js'
// // import './feathericon.js'
// // import './theme_config.js'
// // import './utils.js'
// //
// // import Quill from 'quill/quill';
// // import {
// //   getMetaValue,
// //   toArray,
// //   findElement,
// //   removeElement,
// //   insertAfter
// // } from "helpers";
//
// //--------

export default {
  createVueTurbolinksApp: function(el, opts, legacy) {
    const tmpVar = '_careercamp_app' + Date.now();

    if (legacy) {
      Vue.use(TurbolinksAdapter);

    } else {
      Vue.mixin(Turbolinks);

      document.addEventListener('turbolinks:before-cache', function() {
        if (!document.querySelector(el)) {
          return;
        }

        if (window[tmpVar]) {
          window[tmpVar].$destroy();
        }
      });
    }

    window[tmpVar] = new Vue(opts);
    return window[tmpVar];
  },
  createVueApp: function(opts) {
    const tmpVar = '_app' + Date.now();
    window[tmpVar] = new Vue(opts);
    return window[tmpVar];
  },
  isDocumentPreview: function() {
    return document.documentElement.hasAttribute('data-turbolinks-preview');
  },
  EventBus: new Vue()
};
