<template>
  <div class="row">
    <div class="col-md-12">
      <div v-for="unitModule in unitModules" :key="unitModule.id">
        <div class="unit-module-name">
          <input v-if="unitModuleEditMode"
                 v-model="unitModule.name"
                 @blur="unitModuleBlurOut(unitModule.id, unitModule.name)"
                 v-on:keydown.enter.prevent="unitModuleNameUpdate(unitModule.id, unitModule.name)">
          <div v-if="unitModuleEditMode" @click="removeUnitModule(unitModule.id)" class="unit-module-trash">
            <svg xmlns="http://www.w3.org/2000/svg"
                 width="16"
                 height="16"
                 viewBox="0 0 24 24"
                 fill="none"
                 stroke="currentColor"
                 stroke-width="2"
                 stroke-linecap="round"
                 stroke-linejoin="round"
                 class="feather feather-trash-2 mx-2">
              <polyline points="3 6 5 6 21 6"></polyline>
              <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
              <line x1="10" y1="11" x2="10" y2="17"></line>
              <line x1="14" y1="11" x2="14" y2="17"></line>
            </svg>
          </div>
          <div v-else>
            <h3 @click="unitModuleEditMode = true;">{{unitModule.name}}</h3>
          </div>
        </div>

        <div v-if="unitModule.unit_module_activities.length">
          <div class="unit-module-activities">
            <div v-for="unitModuleActivity in unitModule.unit_module_activities" :key="unitModuleActivity.id">
              <admin-unit-module-activity v-bind:unit-module-activity="unitModuleActivity" v-bind:course-id="courseId" v-bind:unit-id="activeUnitId" v-bind:unit-module-id="unitModule.id" v-if="!onlyRead">
              </admin-unit-module-activity>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="first-activity">
            <div class="card-body">
              <p>No activities</p>
            </div>
          </div>
        </div>
        <div v-if="isActivityFormVisible">
          <admin-unit-module-activity-form v-bind:course-id="courseId" v-bind:unit-id="activeUnitId" v-bind:unit-module="unitModule">
          </admin-unit-module-activity-form>
        </div>
        <a @click.prevent="showActivityForm" data-turbolinks="false" v-if="!isActivityFormVisible" class="btn btn-sm medium-text-bold no-border nav-link nav-link-cc active right" style="margin-top: 1.4rem;">
          + Add new activity
        </a>
      </div>

      <hr>

      <div class="row">
        <div class="col-md-12">
          <a @click.prevent="showModuleFormModal" v-if="!onlyRead" data-turbolinks="false" class="btn btn-sm medium-text-bold no-border nav-link nav-link-cc active">
            + Add new module
          </a>
        </div>
      </div>

      <b-modal ref="unit-module-modal" hide-footer title="Create new unit module" centered>
        <form ref="form" @submit.stop.prevent="handleModuleFormSubmit">
          <b-form-group
            label="Unit module name"
            label-for="unit-module-name-input"
            invalid-feedback="Unit module name is required"
          >
            <b-form-input
              id="unit-module-name-input"
              v-model="newUnitModuleName"
              required
            ></b-form-input>
          </b-form-group>
        </form>
        <div class="mb-2">
          <b-button @click="handleModuleFormOk" class="no-border">Save</b-button>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>
  import CareerCamp from '../globals';
  import axios from 'axios';
  import AdminUnitModuleActivityForm from '../components/admin_unit_module_activity_form.vue';
  import AdminUnitModuleActivity from '../components/admin_unit_module_activity.vue';

  export default {
    props: {
      courseId: String,
      onlyRead: Boolean,
    },
    components: {
      AdminUnitModuleActivityForm,
      AdminUnitModuleActivity
    },
    data() {
      return {
        activeUnitId: null,
        isActivityFormVisible: false,
        unit: null,
        unitModules: [],
        unitModuleEditMode: false,
        newUnitModuleName: '',
      }
    },
    created: function() {
      this.bindEvents();
    },
    destroyed() {},
    mounted() {
      // this.fetchUnitModules();
    },
    watch: {
      activeUnitId: function() {
        this.fetchUnitModules();
      }
    },
    methods: {
      bindEvents: function() {
        const self = this;
        CareerCamp.EventBus.$on('course:units:show', function(data) {
          self.activeUnitId = data
          self.isActivityFormVisible = false;
        });
        CareerCamp.EventBus.$on('course:units:activity:created', function(data) {
          self.isActivityFormVisible = false;
          self.fetchUnitModules();
        });
        CareerCamp.EventBus.$on('course:units:reload', function(data) {
          self.fetchUnitModules();
        });
        CareerCamp.EventBus.$on('course:units:unit_modules:created', function(data) {
          self.fetchUnitModules();
        });
      },
      fetchUnitModules: function() {
        const self = this;
        try {
          axios.get('/admin/courses/' + self.courseId + '/units/' + self.activeUnitId)
            .then(function (response) {
              self.loadUnitModulesData(response.data);
            })
            .catch(function (error) {
              console.log(error);
            })
        }
        catch(e) {
          console.log(e)
        }
      },
      loadUnitModulesData: function(data) {
        this.unit = data;
        this.unitModules = data.unit_modules;
      },
      selectAssignment: function() {

      },
      selectQuiz: function() {

      },
      selectQuestion: function() {

      },
      selectMaterial: function() {

      },
      showActivityForm: function() {
        this.isActivityFormVisible = true
      },
      enableUnitModuleEditMode: function() {
        this.unitModuleEditMode = true;
      },
      disableUnitModuleEditMode: function() {
        this.unitModuleEditMode = false;
      },
      unitModuleBlurOut: function(id, name) {
        this.unitModuleNameUpdate(id, name);
      },
      unitModuleNameUpdate: function(id, name) {
        const self = this;
        const path = '/admin/courses/' + self.courseId + '/units/' + self.activeUnitId + '/unit_modules/' + id

        axios.put(path, {
          unit_module: {
            name: name,
          }
        }).then(function (response) {
          CareerCamp.EventBus.$emit('course:notifications:saved');
          self.fetchUnitModules();
          self.disableUnitModuleEditMode()
        })
        .catch(function (error) {
          console.log(error);
        })
      },
      removeUnitModule: function(id) {
        if(confirm('Are you really sure?')) {
          const self = this;
          const path = '/admin/courses/' + self.courseId + '/units/' + self.activeUnitId + '/unit_modules/' + id

          axios.delete(path).then(function (response) {
            CareerCamp.EventBus.$emit('course:notifications:saved');
            self.fetchUnitModules();
            self.disableUnitModuleEditMode()
          })
          .catch(function (error) {
            console.log(error);
          })
        }
      },
      showModuleFormModal: function() {
        this.$refs['unit-module-modal'].show();
      },
      handleModuleFormSubmit: function() {
        const self = this;
        const path = '/admin/courses/' + self.courseId + '/units/' + self.activeUnitId + '/unit_modules'

        axios.post(path, {
          unit_module: {
            name: self.newUnitModuleName,
            course_unit_id: self.activeUnitId
          }
        }).then(function (response) {
          CareerCamp.EventBus.$emit('course:units:unit_modules:created');
          CareerCamp.EventBus.$emit('course:notifications:saved');
          self.newUnitModuleName = '';
          self.$refs['unit-module-modal'].hide()
        })
        .catch(function (error) {
          console.log(error);
        })
      },
      handleModuleFormOk: function() {
        this.handleModuleFormSubmit();
      }
    }
  };
</script>
