<template>
  <button
    @click="$emit('click')"
    data-turbolinks="false"
    class="btn brand-dark-bg text-white btn-width-110"
  >
    <slot>Submit</slot>
  </button>
</template>

<script>
export default {
  name: "SubmitButton",
};
</script>
