import { Controller } from 'stimulus';

import CareerCamp from '../packs/globals';

import AdminAddUnitButton from '../packs/components/admin_add_unit_button.vue';
import AdminShowUnitButton from '../packs/components/admin_show_unit_button.vue';
import AdminUnitsListButtonsView from '../packs/components/admin_units_list_buttons_view.vue';
import AdminUnitsListView from '../packs/components/admin_units_list_view.vue';
import AdminCoursePersistanceStatus from '../packs/components/admin_course_persistance_status.vue';

export default class extends Controller {
  vueApp = null;

  connect() {
    if (CareerCamp.isDocumentPreview())
      return;

    if(document.querySelector('#course_units') == null) {
      throw new Error('Course units element not present');
    }

    console.log('Loading vueApp...')

    this.vueApp = CareerCamp.createVueTurbolinksApp('#course_units', {
      el: '#course_units',
      components: {
        'admin-add-unit-button': AdminAddUnitButton,
        'admin-show-unit-button': AdminShowUnitButton,
        'admin-units-list-buttons-view': AdminUnitsListButtonsView,
        'admin-units-list-view': AdminUnitsListView
      }
    }, true);


    if(document.querySelector('#course_status') != null) {
      console.log('Loading vueCourseStatusApp...')

      this.vueCourseStatusApp = CareerCamp.createVueTurbolinksApp('#course_status', {
        el: '#course_status',
        components: {
          'admin-course-persistance-status': AdminCoursePersistanceStatus
        }
      }, true);
    }
  }

  disconnect() {
    if (CareerCamp.isDocumentPreview())
      return;

    if (this.vueApp != null)
      this.vueApp.$destroy();

    if (this.vueCourseStatusApp != null)
      this.vueCourseStatusApp.$destroy();

    this.vueApp = null;
    this.vueCourseStatusApp = null;
  }
}
