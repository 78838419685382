<template>
  <div>
    <div class="card card-border-radius-8">
      <div class="subtitle-1 mt-m10 ml-m10">{{tutorialLabel}}</div>
      <video v-if="tutorialVideo != null" width="260" height="170.95" v-bind:src="tutorialVideo" controls class="ml-m30">
        Your browser does not support the video tag.
      </video>
      <img v-if="tutorialImage != null" v-bind:src="tutorialImage" width="200" height="200" />

      <div class="small-text-bold-16px mt-15-mb-15 ml-m10">Your Tutor</div>
      <img src="../../../../assets/images/Your_tutor.png" width="224" height="155" class="ml-m10">
      <div class="verysmall-text-normal ml-m10 mt-15">
       <span class="tutor-desc"> Karen Smith </span> is a leading healthcare employability tutor.
      </div>
    </div>
  </div>
</template>
<script>
  import CareerCamp from '../../globals';
  import axios from 'axios';

  export default {
    props: {
      enrollmentId: String,
      tutorialVideo: String,
      tutorialImage: String,
      tutorialLabel: String,
    },
    data() {
      return {
      }
    },
    created: function() {
      this.bindEvents();
    },
    destroyed() {},
    mounted() {},
    watch: {},
    methods: {
      bindEvents: function() {
      }
    }
  };
</script>
