<template>
  <div class="card p18-20">
    <div class="d-flex justify-content-between">
      <span class="progress-percentage-header">Progress</span>
      <span class="progress-percentage">{{ progressStatus }} %</span>
    </div>
    <div class="progressbar-box mx-0">
      <b-progress :value="progressStatus" :max="100"></b-progress>
    </div>
    <div class="progress-totals my-0">
      <div class="progress-to-complete pl-0">
        <span class="progress-small-bold-txt">To complete:</span>
        <div class="circle gray-bg">{{ toComplete }}/{{ moduleSize }}</div>
      </div>
      <div class="progress-finished pl-0">
        <span class="progress-small-bold-txt">Finished:</span>
        <div class="circle light-blue-bg">{{ finished }}/{{ moduleSize }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CourseProgress",
  props: {
    progressStatus: {
      type: Number,
      required: true,
    },
    toComplete: {
      type: Number,
      required: true,
    },
    moduleSize: {
      type: Number,
      required: true,
    },
    finished: {
      type: Number,
      required: true,
    },
  },
};
</script>
