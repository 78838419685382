<template>
  <div>
    <b-card no-body class="mb-1">
       <b-card-header header-tag="header" class="p-1" role="tab">
         <b-button block v-b-toggle="unitModuleActivity.id" variant="info" class="unit-module-activity-button">
           <span v-if="unitModuleActivity.activity_type == 'question'">Question: {{ unitModuleActivity.title || unitModuleActivity.bodu.question }}</span>
           <span v-if="unitModuleActivity.activity_type == 'material'">Material: {{ unitModuleActivity.title }}</span>
           <span v-if="unitModuleActivity.activity_type == 'assignment'">Assignment: {{ unitModuleActivity.body.title }}</span>
         </b-button>
         <b-dropdown v-if="!onlyRead" toggle-class="btn btn-white course-module-button-toggle" no-caret style="height: 55px;margin-top: -20px;">
           <template v-slot:button-content>
             <svg xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-more-vertical">
               <circle cx="12" cy="12" r="1"></circle>
               <circle cx="12" cy="5" r="1"></circle>
               <circle cx="12" cy="19" r="1"></circle>
             </svg>
           </template>
           <b-dropdown-item @click="enableEditMode">Edit</b-dropdown-item>
           <b-dropdown-item @click="removeUnitModuleActivity">Remove</b-dropdown-item>
         </b-dropdown>
       </b-card-header>
       <b-collapse v-bind:id="unitModuleActivity.id" role="tabpanel">
         <b-card-body>
           <b-card-text>
             <h6 class="learn-activity-title">{{ unitModuleActivity.title }}</h6>
             <br>
             <span v-if="unitModuleActivity.activity_type == 'question' || unitModuleActivity.activity_type == 'material'">
               {{ unitModuleActivity.body.description }}
             </span>
             <div class="col-md-8 p-3" v-if="unitModuleActivity.activity_type == 'question' && unitModuleActivity.newActivityAnswerType == 'single_choice' && unitModuleActivity.newActivityAnswerType == 'multiple_choice'">
               Answers
               <ul>
                 <li v-for="answer in unitModuleActivity.body.answers" :key="answer.id">
                   {{ answer.text }}
                 </li>
               </ul>
             </div>
             <div class="col-md-8 p-3" v-if="unitModuleActivity.images_urls.length >= 1">
               Images
               <ul class="learn-activity-files">
                 <li v-for="(image_url, fileIndex) in unitModuleActivity.images_urls" :key="fileIndex">
                   <a v-bind:href="image_url" target="_blank" class="attachment-link">
                     <img v-bind:src="image_url" />
                   </a>
                 </li>
               </ul>
             </div>
             <div class="col-md-8 p-3" v-if="unitModuleActivity.documents_urls.length >= 1">
               Documents
               <ul class="learn-activity-files">
                 <li v-for="(document_url, fileIndex) in unitModuleActivity.documents_urls" :key="fileIndex">
                   <a v-bind:href="document_url" target="_blank" class="attachment-link">Attachment {{ fileIndex + 1 }}
                   </a>
                 </li>
               </ul>
             </div>
             <div class="col-md-8 p-3" v-if="unitModuleActivity.videos_urls.length >= 1">
               Videos
               <ul class="learn-activity-videos">
                 <li v-for="(video_url, fileIndex) in unitModuleActivity.videos_urls" :key="fileIndex">
                   <video width="320" height="240" v-bind:src="video_url" controls>
                   </video>
                 </li>
               </ul>
             </div>
             <div class="col-md-8 p-3" v-if="unitModuleActivity.tutorial_image">
               Tutorial image
               <img v-bind:src="unitModuleActivity.tutorial_image" style="width: 100%;"/>
             </div>
             <div class="col-md-8 p-3" v-if="unitModuleActivity.tutorial_video">
               Tutorial video
               <video width="320" height="240" v-bind:src="unitModuleActivity.tutorial_video" controls>
               </video>
             </div>
             <br>
             <div>
               {{unitModuleActivity.tutorial_label}}
             </div>
           </b-card-text>
         </b-card-body>
       </b-collapse>
     </b-card>
  </div>
</template>
<script>
  import CareerCamp from '../globals';
  import axios from 'axios';

  export default {
    props: {
      unitModuleActivity: Object,
      onlyRead: Boolean,
      courseId: String,
      unitId: String,
      unitModuleId: String,
    },
    components: {},
    data() {
      return {
        activeUnitId: null,
        isActivityFormVisible: false,
        unit: null,
        unitModules: [],
        unitModuleEditMode: false,
        newUnitModuleName: '',
        editMode: false
      }
    },
    created: function() {
      this.bindEvents();
    },
    destroyed() {},
    mounted() {},
    methods: {
      bindEvents: function() {
        const self = this;
        // CareerCamp.EventBus.$on('course:units:show', function(data) {
        //   self.activeUnitId = data
        //   self.isActivityFormVisible = false;
        // });
        // CareerCamp.EventBus.$on('course:units:activity:created', function(data) {
        //   self.isActivityFormVisible = false;
        //   self.fetchUnitModules();
        // });
        // CareerCamp.EventBus.$on('course:units:unit_modules:created', function(data) {
        //   self.fetchUnitModules();
        // });
      },
      enableEditMode: function() {
        this.editMode = true;
      },
      removeUnitModuleActivity: function() {
        const self = this;
        const path = '/admin/courses/' + self.courseId + '/units/' + self.unitId + '/unit_modules/' + self.unitModuleId + '/unit_module_activities/' + self.unitModuleActivity.id

        axios.delete(path).then(function (response) {
          CareerCamp.EventBus.$emit('course:units:reload');
        })
        .catch(function (error) {
          console.log(error);
        })
      }
    }
  };
</script>
