import { Controller } from 'stimulus';
import CareerCamp from '../packs/globals';
import CourseProgressView from '../packs/components/course_progress_view.vue';
import UnitModuleActivityView from '../packs/components/UnitModules/unit_module_activity_view.vue';
import UnitModuleActivityTutorialView from '../packs/components/UnitModules/unit_module_activity_tutorial_view.vue';

export default class extends Controller {
  vueApp = null;

  connect() {
    if (CareerCamp.isDocumentPreview())
      return;

    if(document.querySelector('#learn_course') == null) {
      throw new Error('Course units element not present');
    }

    this.vueApp = CareerCamp.createVueTurbolinksApp('#learn_course', {
      el: '#learn_course',
      components: {
        'course-progress-view': CourseProgressView,
        'unit-module-activity-view': UnitModuleActivityView,
        'unit-module-activity-tutorial-view': UnitModuleActivityTutorialView,
      }
    }, true);
  }

  disconnect() {
    if (CareerCamp.isDocumentPreview())
      return;

    if (this.vueApp != null)
      this.vueApp.$destroy();

    this.vueApp = null;
  }
}
