<template>
    <div>
        <div class="card card-border-radius-8">
            <div class="row">
                <div class="col-md-6 blue-tutorial-title">
                    Your <span class="blue-text"> next </span> tutorial:
                </div>
                <div class="col-md-6 blue-tutorial-datetimediv d-flex">
                    <span><img src="../../../../assets/images/calendar.png" class="blue-calender"></span>
                    <div class="ml-2 d-flex flex-column">
                        <div class="blue-totorial-date">15th December </div>
                        <div class="blue-totorial-time">3pm</div>
                    </div>
                </div>
            </div>
            <img src="../../../../assets/images/Video-blue.png" width="260" height="170.95" class="ml-m30">
            <div class="small-text-bold-16px mt-15-mb-15 ml-m10">Your Tutor</div>
            <img src="../../../../assets/images/hiring-manager-example.png" width="224" height="155" class="ml-m10">   
            <div class="verysmall-text-normal ml-m10 mt-15">
                <span class="tutor-desc"> Bob Gale </span> is a rail expert and a leader in the free world Bob is a rail expert and a leader in the.
            </div> 
        </div>
    </div>

</template>
<script>
    export default {
  name: "UnitModuleZoomTutorialBlue",
};
</script>