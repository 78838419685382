<template>
  <div>
    <div class="" v-if="!courseFinishedVisible">
      <div v-if="activityVisible" class="learn-activity">
        <div class="card learn-unit-module-activity-title-bar medium-text-bold">
          <span
            ><span class="blue-text" >{{ unitModuleActivity.course_unit.title }} /</span> 
            {{ unitModuleActivity.unit_module.name }}</span
          >
        </div>
      </div>
      <div class="card p-1">
        <div
          class="learn-unit-module-activity row p-2"
          v-if="!unitModuleActivity.is_introduction"
        >
          <div
            v-if="activityVisible"
            class="learn-activity pr-1 col-lg-12"
          >
            <div class="learn-activity-name">{{ unitModuleActivity.name }}</div>

            <div> <span  class="subtitle-1">{{
              unitModuleActivity.body.question
            }}</span> </div>
            <div>
            <span class="lst-nxt-text link-que"> <a class="mr-15"><img src="../../../assets/images/chevron-left.png" style="margin-right: 5px;">   Last</a> <a> Next   <img src="../../../assets/images/chevron-right.png" style="margin-left: 5px;width:5px;height:10px"></a>    </span>  </div>
            
          <!--
            <div class="mt-4 mb-4">
              <p>{{ unitModuleActivity.body.description }}</p>
            </div>
          -->
            <div
              class="col-md-8 p-3"
              v-if="unitModuleActivity.images_urls.size > 0"
            >
              <div class="list-no-style">
                <div
                  v-for="(image_url,
                  fileIndex) in unitModuleActivity.images_urls"
                  :key="fileIndex"
                >
                  <a :href="image_url" target="_blank" class="attachment-link">
                    <img :src="image_url" style="width: 100%" />
                  </a>
                </div>
              </div>
            </div>

            <div
              class="col-md-8 p-3"
              v-if="unitModuleActivity.documents_urls.size > 0"
            >
              <ul class="list-no-style">
                <li
                  v-for="(document_url,
                  fileIndex) in unitModuleActivity.documents_urls"
                  :key="fileIndex"
                >
                  <a
                    v-bind:href="document_url"
                    target="_blank"
                    class="attachment-link"
                    >Attachment {{ fileIndex + 1 }}</a
                  >
                </li>
              </ul>
            </div>

            <div
              class="col-md-8 p-3"
              v-if="unitModuleActivity.videos_urls.size > 0"
            >
              <ul class="list-no-style">
                <li
                  v-for="(video_url,
                  fileIndex) in unitModuleActivity.videos_urls"
                  :key="fileIndex"
                >
                  <video
                   width = "320"
                    height="200"
                    v-bind:src="video_url"
                    controls
                  >
                    Your browser does not support the video tag.
                  </video>
                </li>
              </ul>
            </div>

            <div
              class="col-md-12 bg-gray mt-20"
              v-if="
                unitModuleActivity.activity_type == 'question' ||
                  unitModuleActivity.activity_type == 'assignment'
              "
            >
              <div class="subtitle-1 pt-1 mt-15-mb-15">Student answer</div>
              <hr>
              <div
                class=""
                v-if="unitModuleActivity.body.answer_type == 'text'"
              >
                
                <label class="small-text-bold mt-15-mb-15">Please type your answer:</label>
                <textarea rows="5" class="border-none small-mobile-regular" style="border-radius: 5px;">
                  Uh, look me up when you get there. You're gonna be in the car with her. Hey, hey listen guys. Look, I don't wanna mess with no reefer addicts, okay? Well maybe you are and you just don't know it yet. Right. Uh, look me up when you get there. You're gonna be in the car with her. Uh, look me up when you get…
                </textarea>
              </div>

              <div
                class=""
                v-if="
                  unitModuleActivity.body.answer_type == 'file_upload' ||
                    unitModuleActivity.activity_type == 'assignment'
                "
              >
                <div class="row">
                  <label class="small-text-bold">Please add your files:</label>
                  <div class="col-md-12">
                    <div
                      class="dropzone dropzone-default dz-clickable activity-attachments"
                      ref="attachments"
                      data-controller="dropzone"
                      data-dropzone-max-file-size="200"
                      data-dropzone-max-files="10"
                    >
                      <input
                        multiple="multiple"
                        data-target="dropzone.input"
                        data-direct-upload-url="/rails/active_storage/direct_uploads"
                        type="file"
                        name="unit_module_ativity_response[files][]"
                      />
                      <div class="dropzone-msg dz-message">
                        <span class="dropzone-msg-title"
                          >Drag here to upload or click here to browse</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="col-md-8 p-3"
                v-if="unitModuleActivity.body.answer_type == 'multiple_choice'"
              >
                <label class="small-text-bold"
                  >Please select your answers:</label
                >
                <ul>
                  <li
                    v-for="answer in unitModuleActivity.body.answers"
                    :key="answer.id"
                    class="learn-activity-answers"
                  >
                    <div class="checkbox">
                      <label class="ui-check ui-check-md">
                        <input
                          type="checkbox"
                          v-bind:value="answer.text"
                          v-model="checkedAnswers"
                        />
                        <i class="dark-white"></i>{{ answer.text }}
                      </label>
                    </div>
                  </li>
                </ul>
              </div>

              <div
                class="col-md-8 p-3"
                v-if="unitModuleActivity.body.answer_type == 'single_choice'"
              >
                <label class="small-text-bold"
                  >Please select your answer:</label
                >
                <ul>
                  <li
                    v-for="answer in unitModuleActivity.body.answers"
                    :key="answer.id"
                    class="learn-activity-answers"
                  >
                    <div class="checkbox">
                      <label class="ui-check ui-check-md">
                        <input
                          type="radio"
                          v-bind:value="answer.text"
                          v-model="checkedAnswer"
                        />
                        <i class="dark-white"></i>{{ answer.text }}
                      </label>
                    </div>
                  </li>
                </ul>
              </div>

            </div>
            <div
              class="col-md-12"
              v-if="
                unitModuleActivity.activity_type == 'question' ||
                  unitModuleActivity.activity_type == 'assignment'
              "
            >
              <div class="subtitle-1 pt-1 text-blue mt-15-mb-15">Tutor response</div>
              <hr>
              <div class="mt-15-mb-15">
                <span class="small-text-bold">Answered:</span>  <button class="btn btn-correct medium-text-regular ">Correct</button> <button class="btn btn-feedback medium-text-regular ">Requires feedback</button>
              </div>
            
              <div>
                <label class="small-text-bold">Feedback:</label>
                <textarea rows="5" class="small-mobile-regular" style="border-radius: 8px;border: 2px solid #F2F2F2;">
                
                  Uh, look me up when you get there. You're gonna be in the car with her. Hey, hey listen guys. Look, I don't wanna mess with no reefer addicts, okay? Well maybe you are and you just don't know it yet. Right. Uh, look me up when you get there. You're gonna be in the car with her. Uh, look me up when you get…

                </textarea>
              </div>

              <div class="mt-15-mb-15">
                <div class="small-text-bold" style="padding-bottom: 15px;">This answer has a document attached:</div>
                <div class="card p-1 col-lg-5 d-flex flex-row justify-content-between" style="border: 2px solid #F2F2F2;">
                  <span class="d-flex align-items-center mr-2">       
                      <img src="../../../assets/images/pdf-icon.png" style="margin-right: 5px;"> 
                  </span>
                  <div class="d-flex flex-column justify-content-center">
                    <p class="mat-list-header-text-small">PDF name title</p>
                    <p class="mat-list-desc-text pt-1">300kb</p>
                  </div>
                  <span class="ml-5">
                    <img src="../../../assets/images/feather-icon.png" style="margin-right: 5px;height:20px;width:20px;"> 
                  </span>
                </div>
              </div>

                <div style="padding-top: 20px;padding-bottom: 30px;">
                  <button class="btn  btn-accept" style="height: 40px;">Submit</button>
                </div>

            </div>

            <div
              class="p-3 pb-4"
              v-if="unitModuleActivity.activity_type == 'material'"
            >
              <submit-button @click="submitActivityResponse">
                Next
              </submit-button>
            </div>
            <div class="small-text-regular pt-20 lh-200">
              <span>You are studying:<span class="blue-text"> Priority Skills - Y/615/6994</span> </span><br>
              <span>Qualififcaiton from: <span class="blue-text">Openawards</span> </span>
            </div>
          </div>
          
        </div>

        <div v-if="progressVisible">
          <b-spinner type="border" small></b-spinner>
        </div>

        <div v-if="unitModuleActivity.is_introduction">
          <div class="large-text-bold-title">
            Hello, it’s nice to see you here…
          <!-- {{ unitModuleActivity.body.title }}--> 
          </div>

          <div class="col-md-12 mt-25">
            <video height="350" v-bind:src="unitModuleActivity.tutorial_video" controls style="width: 100%;">
              Your browser does not support the video tag.
            </video>
          </div>

          <div class="medium-text-light p-1 txt-left"> 
            {{ unitModuleActivity.body.description }}
          </div>

          <div class ="row mx-0 mb-3">
            <div class="col-md-6">
              <introduction-description 
              class=""
              :title="introductionMessages[0].title"
              :content="introductionMessages[0].content"
            />
            </div>
            <div class="col-md-6">
                <introduction-description
              class=""
              :title="introductionMessages[1].title"
              :content="introductionMessages[1].content"
            />
            </div>
          </div>

          <div class="text-center mb-3">
            <button
              @click="nextActivity"
              data-turbolinks="false"
              class="btn btn-sm btn-primary no-border btn-padding medium-text-bold-white"
            >
              Get started now!
            </button>
          </div>
        </div>
      </div>
    </div>

    <unit-module-completion v-else />
  </div>
</template>
<script>
import CareerCamp from "../globals";
import axios from "axios";
import UnitModuleActivityTutorialView from "./UnitModules/unit_module_activity_tutorial_view.vue";
import UnitModuleCompletion from "./UnitModules/unit_module_completion.vue";
import IntroductionDescription from "./IntroductionDescription.vue";
import SubmitButton from "./SubmitButton.vue";
import Unitmodulewelldonehalfway from "./UnitModules/unit_module_welldone_halfway.vue"
export default {
  props: {
    enrollmentId: String,
  },
  components: {
    "unit-module-activity-tutorial-view": UnitModuleActivityTutorialView,
    "unit-module-completion": UnitModuleCompletion,
    "introduction-description": IntroductionDescription,
    "submit-button": SubmitButton,
  },
  data() {
    return {
      activityVisible: false,
      unitModuleActivityId: String,
      unitModuleActivity: Object,
      unitModuleActivityResponses: [],
      checkedAnswers: [],
      checkedAnswer: "",
      answerBody: "",
      alertDismissSecs: 4,
      alertDismissCountDown: 0,
      progressVisible: true,
      courseFinishedVisible: false,
      attachments: [],
      enrollment: Object,
      introductionMessages: [
        {
          title: "Quick start guide",
          content: "Uh, look me up when you get there. You're gonna be in the car with her. Hey, hey listen guys. Look, I don't wanna mess with no reefer addicts, okay?",
        },
        {
          title: "Simple to use",
          content: "Uh, look me up when you get there. You're gonna be in the car with her. Hey, hey listen guys. Look, I don't wanna mess with no reefer addicts, okay?",
        },
      ],
    };
  },
  methods: {
    bindEvents: function() {
      const self = this;

      CareerCamp.EventBus.$on("learn:unit_module_activity:select", function(
        data
      ) {
        console.log("ACTIVITY CALL " + data);
        self.unitModuleActivityId = data;
        self.fetchUnitModuleActivity();

        // load answer or clear
        self.checkedAnswers = [];
        self.checkedAnswer = "";
        self.answerBody = "";
      });

      CareerCamp.EventBus.$on("learn:course:finished", function() {
        self.showCourseFinished();
      });
    },
    fetchUnitModuleActivity: function() {
      const self = this;
      self.showProgress();

      try {
        axios
          .get("/learn/api/unit_module_activities/" + self.unitModuleActivityId)
          .then(function(response) {
            console.log({ response_data: response.data });
            self.loadUnitModuleActivityData(response.data);
            self.$forceUpdate();
          })
          .catch(function(error) {
            console.log(error);
          });
      } catch (e) {
        console.log(e);
      }
    },
    loadUnitModuleActivityData: function(data) {
      this.unitModuleActivity = data;
      this.hideProgress();
      this.activityVisible = true;
      this.unitModuleActivity.images.forEach(function(item, index) {});
      this.courseFinishedVisible = false;
      // this.vm.$forceUpdate();
    },
    fetchEnrollment: function() {
      const self = this;
      try {
        axios
          .get("/learn/api/enrollments/" + self.enrollmentId)
          .then(function(response) {
            self.enrollment = response.data;
          })
          .catch(function(error) {
            console.log(error);
          });
      } catch (e) {
        console.log(e);
      }
    },
    submitActivityResponse: function() {
      const self = this;

      self.processAttachments();

      let answer_ids;

      console.log("submitting activity response");

      if (self.unitModuleActivity.body.answer_type == "single_choice") {
        answer_ids = [self.checkedAnswer];
      } else if (
        self.unitModuleActivity.body.answer_type == "multiple_choice"
      ) {
        answer_ids = self.checkedAnswers;
      }

      try {
        axios
          .post("/learn/api/unit_module_activity_responses", {
            unit_module_activity_id: self.unitModuleActivityId,
            enrollment_id: self.enrollmentId,
            files: self.attachments,
            response: {
              answer_ids: answer_ids,
              answer_body: self.answerBody,
            },
          })
          .then(function(response) {
            self.showAlert();
            self.attachments = [];
            console.log({ response_data: response.data });
            self.unitModuleActivityResponses.push(response.data);
            console.log({
              unitModuleActivityResponses: self.unitModuleActivityResponses,
            });
            self.nextActivity();
          })
          .catch(function(error) {
            console.log(error);
          });
      } catch (e) {
        console.log(e);
      }
    },
    processAttachments: function() {
      const self = this;
      const attachment_inputs = document.querySelectorAll(
        '.activity-attachments input[name="unit_module_ativity_response[files][]"]'
      );
      console.log({ attachment_inputs: attachment_inputs });
      Array.prototype.forEach.call(attachment_inputs, function(el, i) {
        if (el.value != "") {
          self.attachments.push(el.value);
        }
      });
    },
    previousActivity: function() {
      CareerCamp.EventBus.$emit(
        "learn:unit_module_activity:previous",
        this.unitModuleActivity.id
      );
    },
    nextActivity: function() {
      console.log("nextActivity");
      CareerCamp.EventBus.$emit(
        "learn:unit_module_activity:next",
        this.unitModuleActivity.id
      );
    },
    isActivityAnswered: function(activity_id) {
      return this.activityAnswer != undefined;
    },
    activityAnswer: function(activity_id) {
      this.unitModuleActivityResponses.find((x) => x.id === activity_id);
    },
    alertCountDownChanged(_alertDismissCountDown) {
      this.alertDismissCountDown = _alertDismissCountDown;
    },
    showAlert() {
      this.alertDismissCountDown = this.alertDismissSecs;
    },
    showProgress() {
      this.progressVisible = true;
    },
    hideProgress() {
      this.progressVisible = false;
      this.activityVisible = true;
    },
    showCourseFinished() {
      this.courseFinishedVisible = true;
      this.activityVisible = false;
    },
  },
  created() {
    this.bindEvents();
    this.fetchEnrollment();
  },
};
</script>
