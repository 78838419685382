<template>
  <div class="inline bg-gray p-3">
    <div class="large-text-bold pb-10">{{ title }}</div>
    <div class="small-text-regular">
      {{ content }}
    </div>
  </div>
</template>

<script>
export default {
  name: "IntroductionDescription",
  props: {
    title: {
      type: String,
      required: true,
    },
    content: {
      type: String,
      required: true,
    }
  }
};
</script>
