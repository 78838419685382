<template>
    <div>
        <div class="card card-border-radius-8 card-bg-purple">
            <div class="purple-zoom-head mt-m10 ml-m10">Meet the employer:</div>
            <img src="../../../../assets/images/Video.png" width="260" height="170.95" class="ml-m30">   
           
             <div class="row mt-25">
                 <div class="col-md-4">
                    <img src="../../../../assets/images/welldonetutor.png" width="68" height="68">   
                 </div>
                 <div class="col-md-8" style="padding-right: 0%;">
                     <div class="purple-zoom-tutorname">Mark Commerford</div>
                     <div class="verysmall-text-normal">HR Manager, Openreach</div>
                     <div class="verysmall-text-normal-130lh mt-5px">Rail expert and a leader in the free world Bob is a rail expert and a leader in the.eader in</div>
                 </div>
             </div>
             <div class="mt-25">
                 <div class="row" style="padding-bottom: 5px;">
                     <div class="col-md-2">
                        <img src="../../../../assets/images/calendar-zoom.png" style="height:24px;width:24px;"> 
                     </div>
                     <div class="col-md-4 verysmall-bold-130lh pl-0-pr-0 mt-6">Date</div>
                     <div class="col-md-6 purple-zoom-schedule-text pl-0-pr-0 mt-6">6th April </div>
                 </div>    
                 <div class="row" style="padding-bottom: 5px;">
                    <div class="col-md-2">
                       <img src="../../../../assets/images/clock-zoom.png"> 
                    </div>
                    <div class="col-md-4 verysmall-bold-130lh pl-0-pr-0 mt-6">Time</div>
                    <div class="col-md-6 purple-zoom-schedule-text pl-0-pr-0 mt-6">12:00pm - 1:00pm </div>
                </div>    
                <div class="row">
                    <div class="col-md-2">
                       <img src="../../../../assets/images/globe.png"> 
                    </div>
                    <div class="col-md-4 verysmall-bold-130lh pl-0-pr-0 mt-6">Booking link</div>
                    <div class="col-md-6 purple-zoom-schedule-text pl-0-pr-0 mt-6">tinyurl.com/1234jgo </div>
                </div>    
                <div class="row">
                    <div class="col-md-2" style="margin-top: 8px;">
                       <img src="../../../../assets/images/down-arrow-circle.png" height="18" width="18"> 
                    </div>
                    <div class="col-md-4 verysmall-bold-130lh pl-0-pr-0 mt-15">or Click here</div>
                </div>
                <div class="row">
                    <div class="col-md-6 pl-0-pr-0">  <img src="../../../../assets/images/ZOOM 1.png" style="mix-blend-mode: multiply; height: 5em;"> </div>
                </div>
             </div>
        </div>
    </div>
</template>
<script>
    export default {
  name: "UnitModuleZoomTutorialPurple",
};
</script>