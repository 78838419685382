// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//

require("jquery")
require("@rails/ujs").start();
require("jquery-ui")
require("@rails/activestorage").start();
require("@nathanvda/cocoon")
require("bootstrap")

import $ from "jquery/dist/jquery";
import "bootstrap/js/dist/dropdown";
import "bootstrap/js/dist/carousel";
import "bootstrap/js/dist/collapse";
import "bootstrap/js/dist/alert";
import "bootstrap/js/dist/button";
import "bootstrap/js/dist/modal";
import "bootstrap/js/dist/popover";
import "bootstrap/js/dist/scrollspy";
import "bootstrap/js/dist/tab";
import "bootstrap/js/dist/toast";
import "bootstrap/js/dist/tooltip";
import "bootstrap/js/dist/util";
import "bootstrap-datepicker";


import { Application } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";
import { data } from "jquery";

const application = Application.start();
const context = require.context("controllers", true, /.js$/);
application.load(definitionsFromContext(context));

document.addEventListener("turbolinks:load", function() {
  window.loaded = true;
  application.controllers
    .filter(function(c) {
      return c.identifier == "selectr";
    })
    .forEach(function(controller) {
      if (typeof controller.initialize === "function") {
        controller.initialize();
      }
    });
});

document.addEventListener("turbolinks:before-cache", function() {
  window.loaded = null;
  application.controllers
    .filter(function(c) {
      return c.identifier == "selectr";
    })
    .forEach(function(controller) {
      if (typeof controller.disconnect === "function") {
        controller.disconnect();
      }
    });
});

$(document).ready(function($) {
  $('#all_employee_select').change(function(){
    var id = $(this).val()
    $.ajax({
      url: "/admin/companies/" + id + "/get_specific_managers",
      type: "POST",
    }).done(function() {
      $('#hiring_manager_id').select2();
      $('.select2-multiple').select2({
        allowClear: false,
        multiple: true
      })
    });
  });

  $('#carouselExampleSlidesOnly').on('slid.bs.carousel', function () {
    var currentIndex = $('.rounded-circle.active').index() + 1;
    var totalItems = $('.by_guest_speaker-count').text().split('/')[1]
    $('.by_guest_speaker-count').html('('+currentIndex+'/'+totalItems+'');
  });

  $(document).on("click", ".clickable-row", function() {
    window.location = $(this).data("href");
  });

  $(document).on("click", ".scroll-down-wrapper", function() {
    var element = document.getElementById("explore-industry");
    element.scrollIntoView({ behavior: "smooth" });
  });

  $(document).on("click", "#guest-modal", function() {
    var link = $(location).attr("href").split("?")[0];
    link = link + "?guest_speaker_id=" + $("#guest_speaker_modal-id").val();
    window.location.replace(link);
  });
});

$(document).on("click", ".dropleft", function() {
  $(this).parent().toggleClass('show');
  $(this).attr('aria-expanded', true);
  $(this).next().toggleClass('show');
});

function sendAjaxRequest(url, courseId) {
  $.ajax({
    url: url,
    type: "GET",
    dataType: 'script',
    data: {
      course_id: courseId
    }
  });
}

$(document).on("change", "#funder-course-select", function() {
  var url = '/funders/dashboard/regional_course';
  var courseId = $(this).val();
  sendAjaxRequest(url, courseId);
});

$(document).on("change", "#funder-local-course-select", function() {
  var url = '/funders/dashboard/local_view_course';
  var courseId = $(this).val();
  sendAjaxRequest(url, courseId);
});

$(document).on("change", "#funder-national-course-select", function() {
  var url = '/funders/dashboard/national_course';
  var courseId = $(this).val();
  sendAjaxRequest(url, courseId);
});
