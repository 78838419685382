<template>
  <div>
    <div v-show="isVisible">Saved!</div>
  </div>
</template>
<script>
  import CareerCamp from '../globals';

  export default {
    name: 'course-persistance-status',
    data: function() {
      return {
        timeToShow: 0,
      };
    },
    created: function() {
      this.bindEvents();
    },
    computed: {
      isVisible: function () {
        return this.timeToShow >= 1;
      }
    },
    methods: {
      bindEvents: function() {
        console.log('HAI STATUS');
        const self = this;

        CareerCamp.EventBus.$on('course:notifications:saved', function(data) {
          console.log('RECEIVED: course:notifications:saved');
          self.timeToShow = 4;
          self.runDown();
        });
      },
      runDown: function() {
        const self = this;

        console.log('TICK')
        console.log(self.timeToShow)

        self.timeToShow = self.timeToShow - 1;

        console.log('oops..');
        console.log(self.timetoShow);

        if (self.timetoShow > 0) {
          console.log('trying to schedule..');
          setTimeout(function() { self.runDown(); }, 1000);
        } else {
          console.log('WHYYYYY');
        }
      }
    }
  }
</script>
