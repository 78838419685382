<template>
  <div class="u-col-12 card p-0" v-if="progressVisible">
    <div class="card-body learn-courses-progress-card">
      <p class="progress-list-header ellipsify">Questions</p>
      <ul class="learn-course-units bordered-list">
        <li v-for="(courseUnit, courseUnitIndex) in enrollment.course.course_units" :key="courseUnit.id" class="main-element closed"
            v-bind:class="{ 'active': isCourseUnitActive(courseUnit), 'finished': isCourseUnitFinished(courseUnit) }">
          <button class="learn-course-unit-link ellipsify verysmall-bold" 
                  v-bind:class="{ 'active': isCourseUnitActive(courseUnit), 'finished': isCourseUnitFinished(courseUnit) }"
                  @click="activateUnit(courseUnit)">
            <span class="verysmall-bold">{{(courseUnitIndex + 1)}}. {{ courseUnit.title }}</span> <div class="finished-unit-tick"> <img src="../../../assets/images/Shape.png"></div> 
          </button>

          <ul class="learn-course-unit-modules">
            <li v-for="(unitModule, unitModuleIndex) in courseUnit.unit_modules" :key="unitModule.id">
              <button class="learn-course-unit-module-link ellipsify ml-0"
                      v-bind:class="{ 'active': isUnitModuleActive(unitModule) }"
                      @click="activateUnitModule(unitModule)">
                <span>{{ unitModule.name }}</span>
              </button>

              <ul class="learn-unit-module-activities">
                <li v-for="(unitModuleActivity, unitModuleActivityIndex) in unitModule.unit_module_activities"
                    :key="unitModuleActivity.id"
                    class="learn-course-unit-module-activity"
                    v-bind:class="{ 'learn-course-unit-module-activity-active': isUnitModuleActivityActive(unitModuleActivity) }">

                  <button class="learn-course-unit-module-activity-link ellipsify ml-0"
                          v-bind:class="{ 'active': isUnitModuleActivityActive(unitModuleActivity), 'answered': isActivityAnswered(unitModuleActivity) }"
                          :id="'unit_module_activity_' + unitModuleActivity.id"
                          @click="activateUnitModuleActivity(unitModuleActivity)"
                          v-if="unitModuleActivity.activity_type == 'question'">
                        <span v-if="isActivityAnswered(unitModuleActivity)  !=  true"> <img src="../../../assets/images/Edge.png">    </span>  
                    <span><b class="verysmall-bold">Q{{(unitModuleActivityIndex + 1)}}.</b> {{ unitModuleActivity.title || unitModuleActivity.body['question'] }}</span>
                  </button>

                  <button class="learn-course-unit-module-activity-link ellipsify ml-0"
                          :class="{ 'active': isUnitModuleActivityActive(unitModuleActivity), 'answered': isActivityAnswered(unitModuleActivity) }"
                          :id="'unit_module_activity_' + unitModuleActivity.id"
                          @click="activateUnitModuleActivity(unitModuleActivity)"
                          v-if="unitModuleActivity.activity_type == 'material'">
                    <span><b class="verysmall-bold">Q{{(unitModuleActivityIndex + 1)}}.</b> {{ unitModuleActivity.title || 'Material' }}</span>
                  </button>

                  <button class="learn-course-unit-module-activity-link ellipsify ml-0"
                          v-bind:class="{ 'active': isUnitModuleActivityActive(unitModuleActivity), 'answered': isActivityAnswered(unitModuleActivity) }"
                          :id="'unit_module_activity_' + unitModuleActivity.id"
                          @click="activateUnitModuleActivity(unitModuleActivity)"
                          v-if="unitModuleActivity.activity_type == 'assignment'">
                    <span><b class="verysmall-bold">Q{{(unitModuleActivityIndex + 1)}}.</b> {{ unitModuleActivity.title || 'Assignment' }}</span>
                  </button>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import CareerCamp from '../globals';
import axios from 'axios';
export default {
  name: 'CourseQuestions',
  props: {
    enrollmentId: String,
  },
  data() {
    return {
      enrollment: null,
      course: null,
      progressVisible: false,
      activeCourseUnitId: null,
      activeUnitModuleId: null,
      activeUnitModuleActivityId: null,
    }
  },
  created() {
    this.bindEvents();
  },
  mounted() {
    this.fetchEnrollment(false)
  },
  computed: {
    activeCourseUnit: function() {
      const self = this;

      if (self.enrollment != null) {
        const courseUnit = self.enrollment.course.course_units.find(x => x.id === self.activeCourseUnitId)
        console.log({ info: 'activeCourseUnit computed', enrollment: self.enrollment, activeCourseUnitId: self.activeCourseUnitId, courseUnit: courseUnit })

        return courseUnit
      }
    },
    activeUnitModule: function() {
      const self = this;

      if (self.enrollment != null) {
        const unitModule = self.activeCourseUnit.unit_modules.find(x => x.id === self.activeUnitModuleId)
        console.log({ info: 'activeUnitModule computed', enrollment: self.enrollment, activeUnitModuleId: self.activeUnitModuleId, unitModule: unitModule })

        return unitModule
      }
    },
    activeUnitModuleActivity: function() {
      const self = this;

      if (self.enrollment != null) {
        const unitModuleActivity = self.activeUnitModule.unit_module_activities.find(x => x.id === self.activeUnitModuleActivityId)
        console.log({ info: 'activeUnitModuleActivity computed', enrollment: self.enrollment, unitModuleActivity: unitModuleActivity })

        return self.activeUnitModule.unit_module_activities.find(x => x.id === self.activeUnitModuleActivityId)
      }
    }
  },
  methods: {
    bindEvents: function() {
      console.log("inside course progress view");
      const self = this;

      CareerCamp.EventBus.$on('learn:unit_module_activity:previous', function(data) {})

      CareerCamp.EventBus.$on('learn:unit_module_activity:next', function(data) {
        console.log({ event_name: 'learn:unit_module_activity:next', activity_id: data })
        self.fetchEnrollment(true);
        self.findNextUnitModuleActivity(data)
      });
    },
    fetchEnrollment: function(onlyUpdate) {
      const self = this;
      try {
        axios.get('/learn/api/enrollments/' + self.enrollmentId)
          .then(function (response) {
            console.log({ info: '/learn/api/enrollments/ call', enrollmentId: self.enrollmentId, data: response.data })

            if(onlyUpdate) {
              self.updateEnrollmentData(response.data);
            } else {
              self.loadEnrollmentData(response.data);
            }
          })
          .catch(function (error) {
            console.log(error);
          })
      }
      catch(e) {
        console.log(e)
      }
    },
    loadEnrollmentData: function(data) {
      this.enrollment = data;

      this.selectCourseUnit(data.course.course_units[0])
      this.selectUnitModule(data.course.course_units[0].unit_modules[0])
      this.selectUnitModuleActivity(data.course.course_units[0].unit_modules[0].unit_module_activities[0])

      this.progressVisible = true;
      this.unitModuleActivityResponses = data.unit_module_activity_responses;
    },
    updateEnrollmentData: function(data) {
      this.enrollment = data;
      this.progressVisible = true;
      this.unitModuleActivityResponses = data.unit_module_activity_responses;
    },
    isCourseUnitActive: function(course_unit) {
      return this.activeCourseUnitId == course_unit.id
    },
    isCourseUnitFinished: function(course_unit) {
    },
    isUnitModuleActive: function(unit_module) {
      return this.activeUnitModuleId == unit_module.id
    },
    selectCourseUnit: function(course_unit) {
      this.activeCourseUnitId = course_unit.id
    },
    selectUnitModule: function(unit_module) {
      this.activeUnitModuleId = unit_module.id
    },
    selectUnitModuleActivity: function(unit_module_activity) {
      this.activeUnitModuleActivityId = unit_module_activity.id
      var id = 'unit_module_activity_'+unit_module_activity.id;
      var yOffset = -10;
      var element = document.getElementById(id);
      var box = document.getElementsByClassName('learn-courses-progress-card')[0]

      if (box !== undefined && element !== null) {
        if (element !== undefined && element !== null) {
          const y = element.getBoundingClientRect().top - box.getBoundingClientRect().top + yOffset;
          box.scrollTo({ top: y, behavior: 'smooth' });
        }
      }

      CareerCamp.EventBus.$emit('learn:unit_module_activity:select', this.activeUnitModuleActivityId);
    },
    isUnitModuleActivityActive: function(unit_module_activity) {
      return this.activeUnitModuleActivityId == unit_module_activity.id
    },
    findNextUnitModuleActivity: function(activity_id) {
      const unitModuleActivityIndex = this.activeUnitModule.unit_module_activities.findIndex(x => x.id === activity_id)

      if (unitModuleActivityIndex >= this.activeUnitModule.unit_module_activities.length - 1) {
        console.log({ info: 'If current activity is the last one in this unit module', activity_id: activity_id })
        console.log({ info: 'Find next unit module within this course unit to select activity', activity_id: activity_id })

        const unitModuleIndex = this.activeCourseUnit.unit_modules.findIndex(x => x.id === this.activeUnitModule.id)

        if (unitModuleIndex >= this.activeCourseUnit.unit_modules.length - 1) {
          console.log({ info: 'If current unit module is the last one in this course unit', activity_id: activity_id })
          console.log({ info: 'Find next unit module within this course unit', activity_id: activity_id })

          const courseUnitIndex = this.enrollment.course.course_units.findIndex(x => x.id === this.activeCourseUnit.id)

          if (courseUnitIndex >= this.enrollment.course.course_units.length - 1) {
            console.log({ info: 'LAST COURSE UNIT OF THIS COURSE FINISHED.', activity_id: activity_id })
            CareerCamp.EventBus.$emit('learn:course:finished');
          }
          else {
            console.log({ info: 'select next course unit in current course', activity_id: activity_id })

            this.selectCourseUnit(this.enrollment.course.course_units[courseUnitIndex + 1])
            this.selectUnitModule(this.activeCourseUnit.unit_modules[0])
            this.selectUnitModuleActivity(this.activeUnitModule.unit_module_activities[0])
          }
        } else {
          console.log({ info: 'select next unit module and select first activity', activity_id: activity_id })

          this.selectUnitModule(this.activeCourseUnit.unit_modules[unitModuleIndex + 1])
          this.selectUnitModuleActivity(this.activeUnitModule.unit_module_activities[0])
        }
      } else {
        console.log({ info: 'select next unit module activity within current unit module', activity_id: activity_id })

        this.selectUnitModuleActivity(this.activeUnitModule.unit_module_activities[unitModuleActivityIndex + 1])
      }
    },
    activateUnit(unit) {
      console.log({ activating: unit })

      this.selectCourseUnit(unit)
      this.selectUnitModule(unit.unit_modules[0])
      this.selectUnitModuleActivity(unit.unit_modules[0].unit_module_activities[0])
    },
    activateUnitModule(unit_module) {
      console.log({ activating: unit_module })

      this.selectCourseUnit(unit_module.course_unit)
      this.selectUnitModule(unit_module)
      // this.selectUnitModuleActivity(unit_module_activity)
    },
    activateUnitModuleActivity(unit_module_activity) {
      console.log({ activating: unit_module_activity })

      this.selectCourseUnit(unit_module_activity.course_unit)
      this.selectUnitModule(unit_module_activity.unit_module)
      this.selectUnitModuleActivity(unit_module_activity)
    },
    isActivityAnswered: function(activity_id) {
      const val = this.activityAnswer != undefined
      console.log({ activity_id: activity_id, answered: val })
      console.log(val);
      return val
    },
    activityAnswer: function(activity_id) {
      return this.unitModuleActivityResponses.find(x => x.id === activity_id)
    }
  }
}
</script>