import { Controller } from 'stimulus';
import CareerCamp from '../packs/globals';
import CourseProgressView from '../packs/components/course_progress_view.vue';
import CourseQuestionsView from '../packs/components/CourseQuestions.vue';
import TutorUnitActivityView from '../packs/components/tutor_unit_activity_view.vue';

export default class extends Controller {
  vueApp = null;

  connect() {
    if (CareerCamp.isDocumentPreview())
      return;

    if(document.querySelector('#tutor') == null) {
      throw new Error('Course units element not present');
    }

    this.vueApp = CareerCamp.createVueTurbolinksApp('#tutor', {
      el: '#tutor',
      components: {
        'course-progress-view': CourseProgressView,
        'course-questions': CourseQuestionsView,
        'tutor-unit-activity-view': TutorUnitActivityView
      }
    }, true);
  }

  disconnect() {
    if (CareerCamp.isDocumentPreview())
      return;

    if (this.vueApp != null)
      this.vueApp.$destroy();

    this.vueApp = null;
  }
}
