<template>
  <div class="card" style="padding:0px !important;width:822px;height:574px" >
    <div class="card-img unit-completion-bg">
   
      <div class="col-md-12 p-5 center ">
        <div class="mb-4 pt-5 mt-1 pb-3 text-center container">
          <h6>
            <span class="blue-text" style="font-size:31px">Congratulations!</span><br />
            <span style="font-size:31px">You've completed the course!</span>
          </h6>
        </div>
        <div class="card done-card row" style="padding-top:1.5rem !important; padding-left: 10px;">
          <div class="col-md-3">
            <img src="../../../../assets/images/Your_tutor.png" style="width: 125px; height: 100px;">
          </div>
          <div class="col-md-9 pl-4">
            <div class="large-text-regular" style="height:67px">
              Your work is now being reviewed by your tutor:
            </div>
            <div class="large-text-bold color-done-text">Karen Smith</div>
            <div class="pup-text">
              Healthcare Employability Tutor

            </div>
          </div>
        </div>

        <div class="done-btn-div">
          <button
            class="btn btn-sm no-border medium-text-bold-white done-btn mt-20"
          >
            Continue to the next unit
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UnitModuleCompletion",
};
</script>
